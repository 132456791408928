.thank-you {
    text-align: center;
    margin-top: 20px; /* Adjust as needed */
    font-size: 24px;
    color: var(--purple22); /* or your preferred color */
    font-family: Monumentextended, sans-serif;
    opacity: 0;
}

.fade-in {
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
