 .work-set-heading {
     width: 100%;
     color: var(--black22);
     text-align: center;
     margin-top: 10px;
     font-family: Monumentextended, sans-serif;
     font-size: 2.75vw;
     line-height: 1;
 }

 .portfolio-container {
    border-top: 2px solid var(--black22);
    border-bottom: 2px solid var(--black22);
    width: 100%;
    background-color: var(--black22);    
 }


 .work-set {
    overflow: hidden;
    display: flex;
    grid-column-gap: 4vw;
    display: flex;
    border-top: 2px solid var(--black22);
    border-bottom: 2px solid var(--black22);
    background-color: var(--gold22);
    box-shadow: 7px 7px 0 0 var(--black22);
    transform: rotate(-0.75deg);

    
 }


 .project-img {
    border-radius: 24px;
 }

 .work-tag22 {
    width: auto;
    border: 2px solid var(--black22);
    background-color: var(--cyan);
    border-radius: 45px;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-left: 25px;
    padding: 12px 16px;
    font-size: 18px;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
    box-shadow: 4px 4px #000;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-8deg) skew(0deg, 0deg);
    opacity: 1;
    transform-style: preserve-3d;
}

.work-tag-text {
    color: var(--black22);
    font-family: Roobert,sans-serif;
    font-weight: 1000;
    font-size: larger;
}

.port-container {
    position: relative;
}

.project-information-dialog {
    animation: fadeIn 0.5s ease-in-out;
    border-radius: 15px;
    padding: 0px;
    max-width: 65%;
  }
  
  
  
  /* Add a fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Adjust the alpha value for opacity */
    z-index: 999; /* Ensure it's above other content */
  }

  .dialog-close {
    transform: rotate(45deg);
    position: absolute;
    right: 12px;
  }

  .media-container {
    background-color: var(--black22);
    padding: 2%;
    padding-top: 0px;
  }


  .media-container .media img,
  .media-container .media iframe{
    width: 100%;
    height: 100%;
    max-width: 27vw;
    max-height: 40vh;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.9); /* Adjust the shadow values as needed */
    transition: box-shadow 0.3s ease; /* Add a smooth transition effect */    
}

  .media-container .media img:hover,
  .media-container .media iframe:hover {
    box-shadow: 0 8px 16px rgb(255, 148, 148); /* Adjust the shadow values for the hover effect */
    
}

.media-container .media {
    margin-top: 3%;

}

  .dialog-header {
    display: flex;
    flex-direction: column;
    position: relative;

    padding: 2%;
    border-bottom: 2px solid var(--black22);
    text-align: center;
}


  .dialog-header h1 {
    color: var(--black22);
    font-family:  Monumentextended,sans-serif;
    font-weight: 1000;
    font-size: 30px;
    width: 100%;
  }

  .work-points {
    border-bottom: 2px solid var(--black22);
    display: flex;
    justify-content: center;
}

.SAR .dialog-header{
    background-color: var(--cyan);
}

.dialog-header .links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 20%;
    margin-top: 1.5%;

}

.pdf-icon {
  max-width: 8%;
}

.ppt-icon {
  max-width: 10%;
}

.dialog-header .present-icon {
  height: 90px;
}

@keyframes heartbeat {
  0%, 50%, 100% {
    transform: scale(1);
  }

  25%, 75% {
    transform: scale(1.2);
  }

}

.heartbeat {
  animation: heartbeat 3s;
}

.career-logo span{
  color: white;
  text-align: center;
  margin-bottom: 0;
  font-family: Roobert,sans-serif;
  font-size: 20px;
}


/* LogoRow.css */
.logo-row-container {
  display: flex;
  justify-content: center;
  column-gap: 15%;
  margin-top: 5%;
  margin-bottom: 10%;
}

.logo {
  width: 100px; /* Set the desired width */
  height: 100px; /* Maintain the aspect ratio */
  filter: brightness(0) invert(1); /* Make the logo white */
}
