.contact-container {
    margin-top: 7%;
    margin-bottom: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
    position: relative;
    border-bottom: 3px solid var(--black22);
}


.contact-container span {
    color: var(--black22);
    text-align: center;
    font-family: Monumentextended,sans-serif;
    font-size: 100px;
    font-weight: 400;
    line-height: 1;
}


.contact-container .form-container {
    border: 3px solid var(--black22);
    border-radius: 30px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    padding: 35px;
    z-index: 100;
    position: relative;
}

.envelope {
    width: 60%;
    position: absolute;
    top: 57%;
    z-index: 0;
    
}

.contact-container .info-row {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3%;
    text-align: left;
    width: 100%;

}


.info-row .name-box, .info-row .address-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
}

.contact-container input, .contact-container textarea{
    border-style: solid;
    border-width: 3px;
    border-color: var(--black22)black black;
    box-shadow: 4px 4px 0 0 var(--black22);
    border-radius: 12px;
    font-size: 20px;
    padding: 20px;
}

.contact-container {
    color: var(--black22);
    font-family: Roobert,sans-serif;
}

.contact-container label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}



.name-box input, .address-box input {
    height: 5vh;
}

.form-container .paragraph {
    width: 100%;
    position: relative;
}

.paragraph .default-text {
    position: absolute;
    padding: 20px;
    font-size: 20px;
    opacity: 0.5;
}

.paragraph textarea{
    width: 100%;
    height: 35vh;
}

.form-container button {
    background-color: var(--mint);
    box-shadow: 4px 4px 0 0 var(--black22);
    color: var(--black22);
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 3px solid #000;
    border-radius: 12px;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 19px 32px 16px;
    font-family: Monumentextended,sans-serif;
    font-size: 16px;
    display: flex;
    width: 100%;
}

.form-container button:hover {
    background-color: #04e361;
}


/* Add this to your CSS file */
.status-popup {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2.5vw;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
 
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Roobert, Monumentextended;
    font-size: 17px;
 
    border-radius: 5px;
  }
  
  .status-popup.success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
  }
  
  .status-popup.error {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
  }
  
  .status-popup .close-popup {
    cursor: pointer;
  }

  .close-popup {
    height: 35px;
    transform: rotate(45deg);
  }
  